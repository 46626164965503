import { z } from "zod";

export const schema = z.object({
  dashboardId: z.string(),
  rankingTrending: z.number().nullable(),
  hoursInTop10: z.number(),
  hoursInTopN: z.number(),
  rankTrend: z.array(z.number()),
});

export type Leaderboard = z.infer<typeof schema>;
