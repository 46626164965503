import { z } from "zod";
import { userRewardsSchema } from "../rewards";

export const userStateSchema = z.object({
  id: z.string(),
  userId: z.string(),
  questId: z.string(),
  lastViewedStep: z.number(),
  agentAddress: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

const userStateUpdateSchema = userStateSchema.pick({ lastViewedStep: true }).partial();

export const questUserStatusSchema = z.union([
  z.literal("STARTED"),
  z.literal("CLAIMED"),
  z.literal("VALIDATING"),
  z.literal("COMPLETED"),
  z.literal("FAILED"),
]);

export const userScoreSchema = z.object({
  id: z.string(),
  sessionId: z.string(),
  userId: z.string(),
  profileId: z.nullable(z.string()),
  address: z.string(),
  score: z.number(),
  chain: z.string(),
  bucketId: z.string(),
  questId: z.string(),
  subScores: z.nullable(z.unknown()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const questStatusSchema = z.object({
  id: z.string(),
  userId: z.string(),
  profileId: z.string(),
  status: questUserStatusSchema,
  // agentAddress is the actual ASCI address as returned from the Treasury API for this User Quest Status
  agentAddress: z.string(),
  validationAttempts: z.number(),
  allocatedValidationAttempts: z.number().default(4),
  validationStartedAt: z.nullable(z.coerce.date()),
  userScoreId: z.string(),
  validatedAt: z.nullable(z.coerce.date()),
  createdAt: z.date(),
  updatedAt: z.date(),
  UserScore: userScoreSchema,
});

export const questUserRewardSchema = z.lazy(() =>
  z.object({
    userReward: userRewardsSchema,
    quest: z.object({
      id: z.string(),
      slug: z.string(),
      chain: z.string(),
      currency: z.string(),
      actionsRequired: z.number(),
      validationSql: z.string(),
      startsAt: z.date(),
      endsAt: z.date(),
      createdAt: z.date(),
      updatedAt: z.date(),
      rewardType: z.string(),
      status: questStatusSchema,
      tokenEarnable: z.number(),
      availableSlots: z.number(),
      totalSlots: z.number(),
    }),
  }),
);

export const questUserStateWithStatusSchema = z.object({
  userState: userStateUpdateSchema,
  status: questUserStatusSchema.optional(),
});

export type QuestUserStatus = z.infer<typeof questUserStatusSchema>;
export type QuestUserState = z.infer<typeof userStateSchema>;
export type QuestUserStateUpdate = z.infer<typeof userStateUpdateSchema>;
export type QuestUserStateWithStatus = z.infer<typeof questUserStateWithStatusSchema>;
export type QuestUserReward = z.infer<typeof questUserRewardSchema>;
